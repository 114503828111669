import React, {useEffect, useLayoutEffect, useState} from "react";
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import {useModal} from "../context";
import Subscribe from "../components/modals/Subscribe";

export default function Home(){
    const {show, hide} = useModal();
    const [opacities, setOpacities] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [sliderRef, instanceRef] = useKeenSlider({
        slides: 3,
        initial: 0,
        loop: true,
        defaultAnimation: {
          duration: 1000
        },
        detailsChanged(s) {
            const new_opacities = s.track.details.slides.map((slide) => slide.portion)
            setOpacities(new_opacities)
        },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
    });

    const [curSl2, setCurSl2] = useState(0);
    const [loadedSl2, setLoadedSl2] = useState(false);
    const [sliderRef2, instanceRef2] = useKeenSlider({
            loop: false,
            slides: {
                perView: 3,
                spacing: 0,
            },
            breakpoints: {
                '(max-width: 1023px)': {
                    slides: {
                        origin: "center",
                        perView: 1.4,
                        spacing: 34 * window.outerWidth / 375,
                    },
                    loop: true,
                },
            },
            slideChanged(slider) {
                setCurSl2(slider.track.details.rel);
            },
            created() {
                setLoadedSl2(true)
            },
        });

    useEffect(() => {
        const handleScroll = event => {
            if(window.CookieConsent){
                const preferences = window.CookieConsent.getUserPreferences();
                if(preferences.acceptType === 'all' && !localStorage.getItem('subscribe3')){
                    show(<Subscribe/>, "modal--subscribe");
                    localStorage.setItem('subscribe3', 'true');
                }
            }

            /*if(!sessionStorage.getItem('subscribe2')){
                show(<Subscribe/>, "modal--subscribe");
                sessionStorage.setItem('subscribe2', 'true');
            }*/
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return(
        <div className="content">
            <div className="sc__promo">
                <div onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.prev()
                } className="carousel-button carousel-button-prev">
                    <svg className="desk-only" width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Previous">
                        <g filter="url(#filter0_d_17_12219)">
                            <circle cx="28" cy="28" r="28" transform="rotate(180 28 28)" fill="#009FE5"></circle>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M32.3107 18.4041C32.3107 18.4041 23.763 22.7548 20.7674 25.6091C17.7717 28.4634 20.7674 30.3632 20.7674 30.3632L31.7897 37.8665C31.7897 37.8665 33.5391 38.4842 33.5391 36.2461C33.5391 34.0081 33.5391 32.4014 33.5391 32.4014C33.5391 32.4014 33.4614 30.7687 26.4043 28.4634C26.4043 28.4634 25.1153 27.6339 33.2101 23.5921C33.2101 23.5921 34.1949 22.7938 33.9498 20.4994C33.7047 18.2049 32.3107 18.4041 32.3107 18.4041Z"
                                  fill="white"></path>
                        </g>
                        <defs>
                            <filter id="filter0_d_17_12219" x="0" y="0" width="63" height="63"
                                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                               result="hardAlpha"></feColorMatrix>
                                <feOffset dx="7" dy="7"></feOffset>
                                <feComposite in2="hardAlpha" operator="out"></feComposite>
                                <feColorMatrix type="matrix"
                                               values="0 0 0 0 0.929412 0 0 0 0 0.898039 0 0 0 0 0.85098 0 0 0 1 0"></feColorMatrix>
                                <feBlend mode="normal" in2="BackgroundImageFix"
                                         result="effect1_dropShadow_17_12219"></feBlend>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_17_12219"
                                         result="shape"></feBlend>
                            </filter>
                        </defs>
                    </svg>
                </div>
                <div onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.next()
                } className="carousel-button carousel-button-next">
                    <svg className="desk-only" width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_259_35592)">
                            <circle cx="28" cy="28" r="28" fill="#009FE5"></circle>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M23.6893 37.5959C23.6893 37.5959 32.237 33.2452 35.2326 30.3909C38.2283 27.5366 35.2326 25.6368 35.2326 25.6368L24.2103 18.1335C24.2103 18.1335 22.4609 17.5158 22.4609 19.7539C22.4609 21.9919 22.4609 23.5986 22.4609 23.5986C22.4609 23.5986 22.5386 25.2313 29.5957 27.5366C29.5957 27.5366 30.8847 28.3661 22.7899 32.4079C22.7899 32.4079 21.8051 33.2062 22.0502 35.5006C22.2953 37.7951 23.6893 37.5959 23.6893 37.5959Z"
                                  fill="white"></path>
                        </g>
                        <defs>
                            <filter id="filter0_d_259_35592" x="0" y="0" width="63" height="63"
                                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                               result="hardAlpha"></feColorMatrix>
                                <feOffset dx="7" dy="7"></feOffset>
                                <feComposite in2="hardAlpha" operator="out"></feComposite>
                                <feColorMatrix type="matrix"
                                               values="0 0 0 0 0.929412 0 0 0 0 0.898039 0 0 0 0 0.85098 0 0 0 1 0"></feColorMatrix>
                                <feBlend mode="normal" in2="BackgroundImageFix"
                                         result="effect1_dropShadow_259_35592"></feBlend>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_259_35592"
                                         result="shape"></feBlend>
                            </filter>
                        </defs>
                    </svg>
                </div>
                <div ref={sliderRef} className="fader">
                    <div className="fader__slide" style={{ opacity: opacities[0] }}>
                        <div className="promo__it promo__it--9">
                            <div className="promo__it-img">
                                <div className="promo__it-img-wrap">
                                    <img src={require("../assets/img/promo-9.jpg")} className="desk-only"/>
                                    <img src={require("../assets/img/promo-9-sm-2.jpg")} className="mb-only"/>
                                </div>

                                <div className="promo__it-ct-bg">
                                    <svg className="desk-only" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="2 0 104 618" width="106.4" height="618"><path fill="white" d="M0-5v675h22.4c-7-28.2-12-59-17-91-6-38.3-4-90.3 2-126 16.6-99.7 37-180.4 63-269 16.4-55.5 36-122.3 36-189H0z"/></svg>
                                    <svg className="mb-only" preserveAspectRatio="none" viewBox="0 0 375 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M267.188 0C186.979 0 146.458 29.375 92.7083 39.3229C38.9583 49.2708 0 31.25 0 31.25V42.5H375V21.3542C375 21.3542 335.495 0 267.188 0Z" fill="white"/>
                                    </svg>

                                </div>
                            </div>
                            <div className="promo__it-ct d-flex align-items-center">
                                <div className="promo__it-ct-bl">
                                    <div className="promo__it-title promo__it-title--over">
                                        Знакомьтесь со&nbsp;зверятами!
                                    </div>
                                    <div className="promo__it-descr">
                                        В&nbsp;новой серии Kinder Natoons вас ждут увлекательные видео о&nbsp;зверятах и&nbsp;их&nbsp;семьях, а&nbsp;также игры с&nbsp;ними. Узнайте, как они растут и&nbsp;осваивают мир вокруг!
                                    </div>
                                    <div className="promo__it-action d-flex">
                                        <a href="https://xn--80adjg1cm4g.xn--d1achhqu.xn--p1ai/?utm_source=banner&utm_medium=bannerkinder&utm_campaign=natoons25" target="_blank" className="btn__promo d-flex align-items-center justify-content-center">
                                            Вперёд к зверятам
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="promo__it-ct-bg2 desk-only">
                                <svg viewBox="0 0 1920 54" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#ffffff" d="M0.2,0 L0,54 L1920,54 L1919.9,0 C1919.9,0 1557,52.9 958.9,53.4 C360.8,53.9 0.2,0 0.2,0 Z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="fader__slide" style={{ opacity: opacities[1] }}>
                        <div className="promo__it promo__it--3">
                            <div className="promo__it-img">
                                <div className="promo__it-img-wrap">
                                    <img src={require("../assets/img/promo-8.jpg")}/>
                                </div>

                                <div className="promo__it-ct-bg">
                                    <svg className="desk-only" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="2 0 104 618" width="106.4" height="618"><path fill="white" d="M0-5v675h22.4c-7-28.2-12-59-17-91-6-38.3-4-90.3 2-126 16.6-99.7 37-180.4 63-269 16.4-55.5 36-122.3 36-189H0z"/></svg>
                                    <svg className="mb-only" preserveAspectRatio="none" viewBox="0 0 375 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M267.188 0C186.979 0 146.458 29.375 92.7083 39.3229C38.9583 49.2708 0 31.25 0 31.25V42.5H375V21.3542C375 21.3542 335.495 0 267.188 0Z" fill="white"/>
                                    </svg>

                                </div>
                            </div>
                            <div className="promo__it-ct d-flex align-items-center">
                                <div className="promo__it-ct-bl">
                                    <div className="promo__it-title promo__it-title--over">
                                        Kinder Pingui <br/>
                                        Мандарин
                                    </div>
                                    <div className="promo__it-descr">
                                        Kinder Pingui Мандарин&nbsp;&mdash; это нежный бисквит с&nbsp;хрустящим шоколадом и&nbsp;молочно-мандариновой начинкой. Вкусный и&nbsp;яркий Kinder Pingui Мандарин прекрасно подойдет для зимнего легкого перекуса.
                                    </div>
                                    <div className="promo__it-action d-flex">
                                        <a href="https://xn--pingui-8nfa9a4b1bnd4b.xn--d1achhqu.xn--p1ai/" target="_blank" className="btn__promo d-flex align-items-center justify-content-center">
                                            Подробнее
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="promo__it-ct-bg2 desk-only">
                                <svg viewBox="0 0 1920 54" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#ffffff" d="M0.2,0 L0,54 L1920,54 L1919.9,0 C1919.9,0 1557,52.9 958.9,53.4 C360.8,53.9 0.2,0 0.2,0 Z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="fader__slide" style={{ opacity: opacities[2] }}>
                        <div className="promo__it">
                            <div className="promo__it-img">
                                <div className="promo__it-img-wrap">
                                    <img src={require("../assets/img/promo-1.avif")}/>
                                </div>

                                <div className="promo__it-ct-bg">
                                    <svg className="desk-only" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="2 0 104 618" width="106.4" height="618"><path fill="white" d="M0-5v675h22.4c-7-28.2-12-59-17-91-6-38.3-4-90.3 2-126 16.6-99.7 37-180.4 63-269 16.4-55.5 36-122.3 36-189H0z"/></svg>
                                    <svg className="mb-only" preserveAspectRatio="none" viewBox="0 0 375 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M267.188 0C186.979 0 146.458 29.375 92.7083 39.3229C38.9583 49.2708 0 31.25 0 31.25V42.5H375V21.3542C375 21.3542 335.495 0 267.188 0Z" fill="white"/>
                                    </svg>

                                </div>
                            </div>
                            <div className="promo__it-ct d-flex align-items-center">
                                <div className="promo__it-ct-bl">
                                    <div className="promo__it-title">
                                        Наша <br className="desk-only"/>
                                        миссия
                                    </div>
                                    <div className="promo__it-descr">
                                        Kinder поддерживает вас в&nbsp;воспитании счастливых детей, предлагая уникальные продукты и&nbsp;создавая радостные моменты, которые не&nbsp;забываются. <br/>
                                        Это важная составляющая в&nbsp;развитии детей, которая всегда была частью нашей истории.
                                    </div>
                                </div>
                            </div>

                            <div className="promo__it-ct-bg2 desk-only">
                                <svg viewBox="0 0 1920 54" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#ffffff" d="M0.2,0 L0,54 L1920,54 L1919.9,0 C1919.9,0 1557,52.9 958.9,53.4 C360.8,53.9 0.2,0 0.2,0 Z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                {loaded && instanceRef.current && (
                    <div className="fader__pagination d-flex justify-content-center">
                        {[
                            ...Array(instanceRef.current.track.details.slides.length).keys(),
                        ].map((idx) => {
                            return (
                                <button
                                    key={idx}
                                    onClick={() => {
                                        instanceRef.current?.moveToIdx(idx)
                                    }}
                                    className={"fader__pagination-dot" + (currentSlide === idx ? " active" : "")}
                                ></button>
                            )
                        })}
                    </div>
                )}
            </div>

            <div className="sc__links">
                <div className="sc__links-decor">
                    <svg className="desk-only" width="1440" height="301" viewBox="0 0 1440 301" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                        <path d="M1550.45 178.393C1477.12 85.4061 1310.7 -143.37 846.461 146.865C419.639 413.654 48.0902 281.557 75.6271 101.37C97.9759 -44.2966 365.363 313.284 -399.085 90.5942" stroke="#009FE5" strokeWidth="2.19497" strokeLinecap="round" strokeDasharray="6.58 13.17"/>
                    </svg>
                    <svg className="mb-only" width="375" height="79" viewBox="0 0 375 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M403.762 46.2482C384.665 22.0329 341.327 -37.5442 220.432 38.0378C109.281 107.514 12.5232 73.1137 19.6942 26.19C25.5142 -11.7439 95.1463 81.376 -103.929 23.3839" stroke="#009FE5" strokeWidth="0.571607" strokeLinecap="round" strokeDasharray="1.71 3.43"/>
                    </svg>
                </div>
                <div className="container">
                    <div className="links__sl-box">
                        <div className="links__sl-row">
                            <div ref={sliderRef2} className="keen-slider">
                                <div className="keen-slider__slide">
                                    <div className="links__sl-it">
                                        <a href="https://www.kinder.com/ru/ru/products" target="_blank" className="links__sl-it-bl">
                                            <div className="links__sl-it-tag">
                                                Продукты
                                            </div>
                                            <div className="links__sl-it-img">
                                                <img src={require("../assets/img/l-img-1.jpg")}/>
                                                <div className="links__sl-it-img-over">
                                                    <svg width="320" height="42" viewBox="0 0 320 42" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                                        <path d="M33.9261 0.652527C29.5376 0.861502 24.5222 1.90638 19.6113 3.78715C11.6703 6.92178 5.40104 10.0564 -1.91309 14.2359V43.2834H321.998V34.9244C287.099 41.7161 283.651 18.9378 283.651 18.9378C282.815 17.0571 281.039 12.1462 277.069 10.0564C268.396 5.8769 250.424 7.96665 237.363 14.0269C220.959 21.3411 198.912 37.6411 183.03 38.686C172.268 39.4174 168.819 34.9244 168.088 31.0584C167.879 29.4911 168.402 27.5058 169.133 25.834C170.387 23.0128 172.268 17.7885 168.819 13.609C164.327 8.07114 149.28 8.90704 149.28 8.90704C118.665 10.5788 105.605 20.4007 82.6173 30.8494C67.989 37.4321 57.5403 39.6264 49.5992 37.7456C48.0319 37.5366 45.2108 36.4917 42.8076 34.611C39.6729 32.3122 36.2248 27.2968 38.3146 22.3859C39.3595 20.5051 40.4043 19.2513 42.912 17.1616C43.4345 16.5346 45.0018 15.0718 45.7332 14.0269C48.6589 9.84743 47.405 3.16023 39.8819 1.48843C39.1505 0.861502 37.5832 0.652527 36.0159 0.652527H33.9261Z" fill="white"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="links__sl-it-ct">
                                                Узнать больше о наших продуктах
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="keen-slider__slide">
                                    <div className="links__sl-it">
                                        <a href="https://www.kinder.com/ru/ru/vkusnoe-kachestvo" target="_blank" className="links__sl-it-bl">
                                            <div className="links__sl-it-tag">
                                                Качество
                                            </div>
                                            <div className="links__sl-it-img">
                                                <img src={require("../assets/img/l-img-2.jpg")}/>
                                                <div className="links__sl-it-img-over">
                                                    <svg width="320" height="42" viewBox="0 0 320 42" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                                        <path d="M33.9261 0.652527C29.5376 0.861502 24.5222 1.90638 19.6113 3.78715C11.6703 6.92178 5.40104 10.0564 -1.91309 14.2359V43.2834H321.998V34.9244C287.099 41.7161 283.651 18.9378 283.651 18.9378C282.815 17.0571 281.039 12.1462 277.069 10.0564C268.396 5.8769 250.424 7.96665 237.363 14.0269C220.959 21.3411 198.912 37.6411 183.03 38.686C172.268 39.4174 168.819 34.9244 168.088 31.0584C167.879 29.4911 168.402 27.5058 169.133 25.834C170.387 23.0128 172.268 17.7885 168.819 13.609C164.327 8.07114 149.28 8.90704 149.28 8.90704C118.665 10.5788 105.605 20.4007 82.6173 30.8494C67.989 37.4321 57.5403 39.6264 49.5992 37.7456C48.0319 37.5366 45.2108 36.4917 42.8076 34.611C39.6729 32.3122 36.2248 27.2968 38.3146 22.3859C39.3595 20.5051 40.4043 19.2513 42.912 17.1616C43.4345 16.5346 45.0018 15.0718 45.7332 14.0269C48.6589 9.84743 47.405 3.16023 39.8819 1.48843C39.1505 0.861502 37.5832 0.652527 36.0159 0.652527H33.9261Z" fill="white"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="links__sl-it-ct">
                                                Качество и ингредиенты
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="keen-slider__slide">
                                    <div className="links__sl-it">
                                        <a href="https://vk.com/kinder" target="_blank" className="links__sl-it-bl">
                                            <div className="links__sl-it-tag">
                                                VK.COM
                                            </div>
                                            <div className="links__sl-it-img">
                                                <img src={require("../assets/img/l-img-3.jpg")}/>
                                                <div className="links__sl-it-img-over">
                                                    <svg width="320" height="42" viewBox="0 0 320 42" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                                        <path d="M33.9261 0.652527C29.5376 0.861502 24.5222 1.90638 19.6113 3.78715C11.6703 6.92178 5.40104 10.0564 -1.91309 14.2359V43.2834H321.998V34.9244C287.099 41.7161 283.651 18.9378 283.651 18.9378C282.815 17.0571 281.039 12.1462 277.069 10.0564C268.396 5.8769 250.424 7.96665 237.363 14.0269C220.959 21.3411 198.912 37.6411 183.03 38.686C172.268 39.4174 168.819 34.9244 168.088 31.0584C167.879 29.4911 168.402 27.5058 169.133 25.834C170.387 23.0128 172.268 17.7885 168.819 13.609C164.327 8.07114 149.28 8.90704 149.28 8.90704C118.665 10.5788 105.605 20.4007 82.6173 30.8494C67.989 37.4321 57.5403 39.6264 49.5992 37.7456C48.0319 37.5366 45.2108 36.4917 42.8076 34.611C39.6729 32.3122 36.2248 27.2968 38.3146 22.3859C39.3595 20.5051 40.4043 19.2513 42.912 17.1616C43.4345 16.5346 45.0018 15.0718 45.7332 14.0269C48.6589 9.84743 47.405 3.16023 39.8819 1.48843C39.1505 0.861502 37.5832 0.652527 36.0159 0.652527H33.9261Z" fill="white"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="links__sl-it-ct">
                                                Социальные сети
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {loadedSl2 && instanceRef2.current && (
                            <div className="fader__pagination d-flex justify-content-center mb-only">
                                {[
                                    ...Array(instanceRef2.current.track.details.slides.length).keys(),
                                ].map((idx) => {
                                    return (
                                        <button
                                            key={idx}
                                            onClick={() => {
                                                instanceRef2.current?.moveToIdx(idx)
                                            }}
                                            className={"fader__pagination-dot" + (curSl2 === idx ? " active" : "")}
                                        ></button>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}